import {AttachmentNamespace, Attachments} from "#/src/utils/attachment-storage";

import {ApiArticle} from "#/src/definitions/ApiDefinitions";
import {AdWrapper} from "#/src/components/AdWrapper";

interface Props {
  article: ApiArticle,
  lazy?: boolean
}

//special card type where data is ad campaign data
export function AdArticleCard({article, lazy = false}: Props) {
  return <AdWrapper ad={{id: article.id, campaignUrl: article.slug}}>
    <article className="article-card">
      <figure className="article-img">
        <img loading={lazy ? "lazy" : "eager"} src={Attachments.getUrl(AttachmentNamespace.AD, article)} alt={article.description}/>
      </figure>
      <div className="article-content">
        <div className="article-tag">
          <a href={"#"} className="tag-primary">Reklama</a>
        </div>
        <a href={"#"} className="article-link">{article.title}</a>
      </div>
    </article>
  </AdWrapper>
}